import isotope from 'isotope-layout/dist/isotope.pkgd.js';

function initFilter() {
	var $grid = $('.articles-blog').isotope({
		itemSelector: '.article-blog'
	});

	$('.nav-filter').on( 'click', 'a', function() {
		var current = $(this).parent();
		console.log(current);
	  	var filterValue = $(this).attr('data-filter');
	  	$grid.isotope({ filter: filterValue });
	  	current
	  		.addClass('is-active')
	  			.siblings()
	  				.removeClass('is-active')
	});
}

$(window).on('load', function(){
	initFilter();
})
