import { $window, $isInViewport } from '../utils/globals.js';

$window.on('load resize scroll', function() {
	$('.js-animation-line').each(function() {
		const $lineWidth = $(this)
			.children('svg')
			.width();

		const $letterWidth = $(this)
			.siblings('span')
			.width();

		if ($(this).isInViewport()) {
			$(this)
				.addClass('is-active')
				.width($lineWidth);

			if ($window.width() < 991) {
				$(this)
					.children('svg')
					.width($letterWidth);
			}
		} /*else {
			$(this)
				.removeClass('is-active')
				.width(0);
		}*/
	});
});
