import { $window, $body, $header, $document } from '../utils/globals.js';

let cachedScrollPos;
$('.nav-trigger').on('click', function(e) {
	e.preventDefault();
	cachedScrollPos = $window.scrollTop();
	$body.toggleClass('is-fixed');
	$body.css('top', -cachedScrollPos);
	$(this).toggleClass('is-active');
	$('.header .header__aside').toggleClass('is-active');
	e.stopPropagation();
	if ($('.nav-trigger').hasClass('is-active') === false) {
		$body.css('top', 'auto');
		window.scrollTo(0, cachedScrollPos);
	}
});
