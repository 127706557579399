$('.js-field').on('focusin', function() {
	$(this)
		.parent()
		.find('label')
		.addClass('is-active');
});

$('.js-field').on('focusout', function() {
	if (!this.value) {
		$(this)
			.parent()
			.find('label')
			.removeClass('is-active');
	}
});
