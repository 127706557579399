export const $window = $(window);
export const $document = $(document);
export const $body = $('body');
export const $header = $('.js-header');
export const $headerAlt = $('.js-header-alt');
export const $hasBar = $('.wrapper.has-bar');

$.fn.isInViewport = function() {
	var elementTop = $(this).offset().top;
	var elementBottom = elementTop + $(this).outerHeight();

	var viewportTop = $window.scrollTop();
	var viewportBottom = viewportTop + $window.height();

	return elementBottom > viewportTop && elementTop < viewportBottom;
};
