import 'bootstrap';
/*
 * Polyfills
 */
import './polyfills/object-fit';

/*
 * Modules
 */

import './modules/fixed-header';
import './modules/line-animation';
import './modules/nav-trigger';
import './modules/aos';
import slidersUI from './modules/slider';
import './modules/popup';
import './modules/label-animation';
import './modules/filter';
import './modules/load-more';

slidersUI();
