import Swiper from 'swiper/js/swiper';

export default function slidersUI() {
	initSliderWork();
}

const initSliderWork = (element = '.js-slider') => {
	const $slider = $(element).find('.swiper-container');
	if (!$slider.length) {
		return;
	}
	const settings = {
		slidesPerView: 'auto',
		loop: true,
		loopedSlides: 4,
		centeredSlides: true,
		spaceBetween: 20,
		draggable: true,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		breakpoints: {
			320: {
				spaceBetween: 20,
			},

			768: {
				spaceBetween: 24,
			},

			1441: {
				spaceBetween: 48,
			},
		},
	};
	$slider.each(function() {
		var mySwiper = new Swiper(this, settings);

		/*mySwiper.on('slideChange', function() {
			$('.slider .slider__clip').addClass('is-active');
			$('.swiper-button-prev').removeClass('hidden');
		});*/
	}); 
};


(function() {	
	const breakpoint = window.matchMedia( '(min-width:768px)' );

	let swiper;

	const breakpointChecker = function() {

	if ( breakpoint.matches === true ) {

	  if ( swiper !== undefined ) swiper.destroy( true, true );

	  return;

	  } else if ( breakpoint.matches === false ) {
	    return enableSwiper();

	  }
	};

	const enableSwiper = function() {
		swiper = new Swiper('.nav-slider', {
			slidesPerView: 4,
			initialSlide: 0,
		    spaceBetween: 25,
		});
	};

	breakpoint.addListener(breakpointChecker);
	breakpointChecker();
})();
