import { $window, $body, $header, $document } from '../utils/globals.js';
import 'magnific-popup';

let cachedScrollPos;
$('.js-popup-contact').each(function() {
	$(this).magnificPopup({
		type: 'inline',
		fixedBgPos: true,
		fixedContentPos: true,
		callbacks: {
			open: function() {
				const $lineWidth = $('.js-animation-line-secondary')
					.children('svg')
					.width();

				const $letterWidth = $('.js-animation-line-secondary')
					.siblings('span')
					.width();

				$('.js-animation-line-secondary')
					.addClass('is-active')
					.width($lineWidth);

				if ($document.width() < 991) {
					$('.js-animation-line-secondary')
						.children('svg')
						.width($letterWidth);
				}
			},
		},
	});

	if ($window.width() <= 1024) {
		$(this).magnificPopup({
			type: 'inline',
			fixedBgPos: true,
			fixedContentPos: true,
			callbacks: {
				open: function() {
					const $lineWidth = $('.js-animation-line-secondary')
						.children('svg')
						.width();

					const $letterWidth = $('.js-animation-line-secondary')
						.siblings('span')
						.width();

					$('.js-animation-line-secondary')
						.addClass('is-active')
						.width($lineWidth);

					if ($document.width() < 991) {
						$('.js-animation-line-secondary')
							.children('svg')
							.width($letterWidth);
					}
				},
			},
		});
	}
});
