import isotope from 'isotope-layout/dist/isotope.pkgd.js';

$(".load-more").click(function(e) {
    e.preventDefault();

    var $this       = $(this)
    var $container  = $('.articles-blog')
    var url         = $this.attr('href')
    var filterValue = $('.nav-filter .is-active a').data('filter') || '*'

    $.ajax({
        method : 'GET',
        type   : 'HTML',
        url    : url,
        async  : false,

        success: function(response) {
            var $response = $(response);

            $container.append($response)

            var $nextLink = $container.find('.more-items')

            if ( $nextLink.length ) {
                $this.attr('href', $nextLink.attr('href'))
              
                $nextLink.remove()
            } else {
                $container
                    .closest('.section-blog')
                        .addClass('hide-actions')
            }

            
            setTimeout(function() {
                $container
                    .isotope('appended', $response)

                $container.isotope({ filter: filterValue });
            }, 50);
        }
    });
});