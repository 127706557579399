import {
	$window,
	$body,
	$header,
	$document,
	$headerAlt,
	$hasBar,
} from '../utils/globals.js';

$.fn.isInViewport = function() {
	if ($(this).offset()) {
		var elementTop = $(this).offset().top;
		var elementBottom = elementTop + $(this).outerHeight();

		var viewportTop = $(window).scrollTop();
		var viewportBottom = viewportTop + $(window).height();

		return elementBottom > viewportTop && elementTop < viewportBottom;
	}
};

$window.on('scroll load', function() {
	const scrollTop = $window.scrollTop();
	const $headerSlogan = $('.js-header-slogan');
	const $headerShell = $('.js-header-shell');

	// This makes the "Let's Talk" button sticky / not sticky
	if (scrollTop) {
		/*$headerAlt.addClass('is-fixed');
		$headerSlogan.addClass('is-active');
		$headerShell.addClass('is-active');
		$('.js-logo').addClass('is-hidden');
		$('.nav').addClass('hidden');*/
		$headerAlt.addClass('is-active');
		$('.js-button').addClass('is-active');
	} else {
		/*$headerAlt.removeClass('is-fixed');
		$headerSlogan.removeClass('is-active');
		$headerShell.removeClass('is-active');
		$('.js-logo').removeClass('is-hidden');
		$('.nav').removeClass('hidden');*/
		$headerAlt.removeClass('is-active');
		$('.js-button').removeClass('is-active');
	}

	// This shows the "Let's Talk" bar sticky / not sticky
	if ($('.footer').isInViewport() || ($hasBar.length && scrollTop > 60) ) {
		$headerAlt.addClass('is-fixed');
		$('.js-header-alt .js-header-slogan').addClass('is-active');
		$('.js-header-alt .js-header-shell').addClass('is-active');
		$('.js-header-alt .js-logo').addClass('is-hidden');
		$('.js-header-alt .nav').addClass('hidden');
		$('.js-header-alt .js-button').addClass('is-align');
		$('.header__aside').removeClass('is-active');
	} else {
		$headerAlt.removeClass('is-fixed');
		$('.js-header-alt .js-header-slogan').removeClass('is-active');
		$('.js-header-alt .js-header-shell').removeClass('is-active');
		$('.js-header-alt .js-logo').removeClass('is-hidden');
		$('.js-header-alt .nav').removeClass('hidden');
		$('.js-header-alt .js-button').removeClass('is-align');
	}

	if ($('.js-main').isInViewport()) {
		$header.removeClass('is-fixed');
		$headerSlogan.removeClass('is-active');
		$headerShell.removeClass('is-active');
		$('.js-logo').removeClass('is-hidden');
		$('.nav').removeClass('hidden');
		$('.js-button').removeClass('is-active');
	}
});
